
import { Vue, Component, Prop } from 'vue-property-decorator'
import { quoteDetailV2 } from '@/services/charterup/quotes'
import { quoteInvoicePDFByHashAndCompanyId } from '@/services/quotes'
import { createDownloadUri, triggerFileDownload } from '@/utils/download'
import { sendQuoteBidInvoiceEmail } from '@/services/quotes'
import { isNotEmpty, isRequired } from '@/utils/validators'

@Component({})
export default class QuoteFormPDFInvoiceSidebar extends Vue {

  @Prop({ type: Number, required: true }) readonly quoteId: number

  bids = []
  selectedBidProviderId: number = null
  quoteHash: string = null
  loading: boolean = false
  submitting: boolean = false

  isNotEmpty = isNotEmpty
  isRequired = isRequired

  get bidOptions() {
    const sortedBids = [...this.bids]
    sortedBids.sort((a, b) => a.totalAmount - b.totalAmount)

    return sortedBids.map((bid) => {
      const truncatedName =
        bid.companyDBAName.length > 30
          ? `${bid.companyDBAName.substring(0, 30)}`
          : bid.companyDBAName
      const bidAmount = bid.totalAmount
        ? `$${bid.totalAmount}`
        : 'No Bid Amount'
      return {
        value: bid.companyId,
        text: `${truncatedName} - ${bidAmount}`,
      }
    })
  }

  async mounted() {
    await this.getBidsForQuoteId()
  }

  close() {
    this.$store.dispatch('app/closeDialog')
  }

  async getBidsForQuoteId() {
    this.loading = true
    const quoteDetail = (await quoteDetailV2(this.quoteId)).data.data
    this.bids = quoteDetail.bids
    this.quoteHash = quoteDetail.hash
    this.loading = false
  }

  async submit() {
    this.loading = true
    try {
      await sendQuoteBidInvoiceEmail(this.quoteId, this.selectedBidProviderId)
      this.close()
    } catch (error) {
      console.log(error)
    }
    this.loading = false
  }

  async downloadQuoteInvoice(): Promise<void> {
    /*
      Since the PDF service does not currently have an endpoint to return quote invoices
      with content-type 'application/pdf' (currently only supports JSON), we cannot simply
      include an a-tag with the URL as it's download property. Instead, we need to manually
      parse the base-64 encoded string returned from the endpoint, convert it to a blob,
      and trigger the file download by creating and re-clicking an invisible a-tag.

      This implementation is definitely quite wacky and non-ideal. However, since we're
      actively searching for a replacement to the PDF service, we want to avoid adding more
      to it to support this use case. We'll have to live with the weird download implementation
      for now and replace this when we've found another option.
    */

    this.loading = true
    try {
      // Fetch the Base64 encoded PDF
      const response = await quoteInvoicePDFByHashAndCompanyId(this.quoteHash, this.selectedBidProviderId)

      if (!response?.data) {
        console.warn('Failed to download quote because of failed network call.')
        this.$store.dispatch('app/showAlert', {
          message: 'Failed to download quote',
          type: 'error',
        })
        return;
      }

      const blobUrl = createDownloadUri(response.data, 'application/pdf');
      triggerFileDownload(blobUrl, `charterup-quote-${this.quoteId}-${this.selectedBidProviderId}.pdf`);

    } catch (error) {
      console.warn('Failed to download quote because of an error.', error)
      this.$store.dispatch('app/showAlert', {
        message: 'Failed to download quote',
        type: 'error',
      })
    }
    this.loading = false
  }
}
